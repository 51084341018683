








































































































import { Inject, Ref, Vue, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import EcSnackBar from '@/components/common/ec-snackbar.vue';
import Card from '@/components/material/Card.vue';
import CaseEmailDropdown from '@/components/case/case-email-dropdown.vue';
import CaseLinks from '@/components/case/case-links.vue';
import StatusControl from '@/components/status-control.vue';
import AssignmentControl from '@/components/assignment-control.vue';
import CaseService from '@/services/case-service';
import UserService from '@/services/user-service';
import ScreeningViewService from '@/services/screening-view-service';
import Notary from '@/services/notary';
import StatusService, { MetadataValuesModel } from '@/services/status-service';
import { CaseStatusEntry, User } from '@/models/case-maintenance.d';
import { CaseView, MatchView } from '@/models/case-maintenance';
import { CaseStatus } from '@/models/case-status';
import { SnackbarOptions } from '@/models/form';
import * as snackbarMessaging from '@/helpers/snackbarMessaging';
import delay from '@/helpers/delay';
import { AxiosError } from 'axios';
import EmailTemplateService from '@/services/email-template-service';
import { EmailTemplate } from '@/models/email-templates';
import ConfigurationService, { AllStatusesConfig } from '@/services/configuration-service';
import StatusConfigDialog from '@/components/configuration/status-config-dialog.vue';
import axios, { CancelTokenSource } from 'axios';
import { HalResponse, HalPageInfo, PaginationOptions } from '@/models/hal';
import { CaseListOptions } from '@/models/session-state-types';
import { getModule } from 'vuex-module-decorators';
import SessionState from '@/store/modules/session-state-module';
import CaseDetails from '@/components/case/case-details.vue';
import { caseTabs, getCaseDetails, UpdateState } from '@/views/CaseFunc';
import CaseTabs from '@/components/case/case-tabs.vue';
import CaseToolbar from '@/components/case/case-toolbar.vue';
import CaseNavigation from '@/components/case/case-navigation.vue';
import CaseCreated from '@/components/case/case-created.vue';
import FindToken from '@/components/helper/TokenPathFinder/TokenPathFinder';
import { Dictionary } from '@/types';
import { FilterField } from '@/models/filters';

const sessionState = getModule(SessionState);
type GotoCasePayload = {
  caseListPage: HalPageInfo['page'];
  cases: HalResponse<CaseView>[];
  caseListOptions: CaseListOptions;
};

@Component({
  components: {
    Card,
    EcSnackBar,
    CaseEmailDropdown,
    CaseLinks,
    AssignmentControl,
    StatusControl,
    StatusConfigDialog,
    CaseDetails,
    CaseTabs,
    CaseToolbar,
    CaseNavigation,
    CaseCreated
  }
})
export default class CasePage extends Vue {
  @Inject() UserService!: UserService;
  @Inject() CaseService!: CaseService;
  @Inject() StatusService!: StatusService;
  @Inject() Notary!: Notary;
  @Inject() ScreeningViewService!: ScreeningViewService;
  @Inject() EmailTemplateService!: EmailTemplateService;
  @Inject() ConfigurationService!: ConfigurationService;

  @Ref('caseTabs') readonly caseTabsRef!: CaseTabs;
  @Ref('toolbar') readonly toolbarRef!: CaseToolbar;

  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();
  cancelToken?: CancelTokenSource;

  booking: any = {};
  emailTemplates = [] as EmailTemplate[];
  users: User[] = [];
  statusMetadataConfig: AllStatusesConfig = { statuses: [] };

  bookingLoading = true;
  caseLoading = true;
  showBackButton = true;
  showStatusConfigDialog = false;

  statusUpdateState: UpdateState = UpdateState.Default;
  statusError = '';

  assignmentUpdateState: UpdateState = UpdateState.Default;
  assignmentError = '';

  newCaseStatus = CaseStatus.New;
  activeCaseTab = 0;

  configModel: CaseStatusEntry = {
    status: 0,
    'created-by': '',
    'created-at': new Date()
  };

  get assignedUser(): string | null {
    return this.caseModel['assigned-to'];
  }

  get caseDetails() {
    return getCaseDetails(this.caseModel);
  }

  get caseDetailsLoaded() {
    return this.caseModel?.['case-details']?.length > 0;
  }

  get caseId(): string {
    return this.caseModel ? this.caseModel['case-id'] : '';
  }

  get caseListLength() {
    return sessionState.caseListPage.totalElements;
  }

  get caseModel(): CaseView {
    return sessionState.case;
  }

  get configTemplate() {
    return this.statusMetadataConfig.statuses.filter(x => x.id === this.newCaseStatus)[0];
  }

  get currentCaseNumber() {
    return sessionState.casePosition;
  }

  get displayCaseNavigation() {
    return Boolean(sessionState.case && sessionState.cases.length > 1);
  }

  get showEditIcon() {
    const result = this.statusMetadataConfig.statuses.find(x => x.id === sessionState.case.status);

    return result != null && result.metadata.length != 0;
  }

  @Watch('$route', { immediate: true })
  routeChanges() {
    if (this.$route.query.path) {
      this.activeCaseTab = 1;
    }
  }

  @Watch('activeCaseTab')
  resetRoute() {
    const activeTab = caseTabs.find(tab => tab.id == this.activeCaseTab);
    if (activeTab?.name !== 'Booking') {
      this.deselectHit();
      if (this.$route.query.path) {
        this.$router.replace({ query: {} }).catch(() => true);
      }
    }
  }

  async mounted(): Promise<void> {
    this.caseLoading = true;
    this.processGotoCasePayload();
    this.reloadCase(this.$route.params.id).then(() => this.loadBookingWithErrorHandling());
    this.loadUsers();
    this.loadEmailTemplates();
    this.statusMetadataConfig = await this.ConfigurationService.getStatusMetadataConfig();
  }

  processGotoCasePayload() {
    const payloadJSON = window.sessionStorage.getItem('gotoPayload');
    if (payloadJSON) {
      this.showBackButton = false;
      const payload = JSON.parse(payloadJSON ?? '{}');

      sessionState.setCaseListPage(payload['caseListPage']);
      sessionState.setCases(payload['cases']);
      sessionState.setCaseListOptions(payload['caseListOptions']);
    }
  }

  async reloadCase(caseId?: string) {
    this.caseLoading = true;
    await sessionState.reloadCase(caseId);
    this.caseLoading = false;
  }

  async loadBookingWithErrorHandling() {
    await this.loadBooking().catch(
      () =>
        (this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('Unable to load booking data'))
    );
  }

  async loadUsers() {
    const users = await this.UserService.listUsers({
      size: 100,
      sort: ['name,asc'],
      includeHidden: false
    });

    this.users = users._embedded.users;
  }

  async loadEmailTemplates() {
    const templates = await this.EmailTemplateService.list();

    this.emailTemplates = templates._embedded['email-templates'].filter(x => !x['automated-only']);
  }

  async loadBooking(): Promise<void> {
    const screeningId = this.caseModel['screen-results'][0]['screening-id'];

    if (!screeningId) return Promise.reject('Screening Id not found in case model');
    this.bookingLoading = true;
    this.booking = await this.ScreeningViewService.readScreening(screeningId);
    this.bookingLoading = false;
  }

  async goToCase(value: number) {
    const caseLoadingTask = delay(200).then(
      () => ((this.caseLoading = true), (this.bookingLoading = true))
    );

    this.resetCurrentCaseState();

    if (value > 0) await sessionState.nextCase();
    else await sessionState.previousCase();

    this.$router
      .replace({ name: 'case', params: { id: sessionState.case['case-id'] } })
      .catch(() => true);

    await caseLoadingTask;

    await this.loadBookingWithErrorHandling().then(
      () => ((this.caseLoading = false), (this.bookingLoading = false))
    );
  }

  resetCurrentCaseState() {
    this.deselectHit();
    this.booking = null;
    this.statusUpdateState = UpdateState.Default;
    this.assignmentUpdateState = UpdateState.Default;
    this.assignmentError = '';
    this.statusError = '';
  }

  goToCaseList() {
    const query = sessionState.caseListQuery;
    this.$router.push({ name: 'cases', query: query }).catch(() => {
      return true;
    });
  }

  async gotoMatchingCases(gotoMatchingCasesSearchTerm: string, filterFields?: FilterField[]) {
    if (gotoMatchingCasesSearchTerm !== '') {
      const pagination = {
        page: 1,
        size: sessionState.caseListPage.size
      } as PaginationOptions;

      const gotoFilter = {
        key: 'id',
        value: gotoMatchingCasesSearchTerm,
        operator: ''
      };
      const filter = [gotoFilter];

      const assignment = sessionState.caseListAssignment;

      if (this.cancelToken) this.cancelToken.cancel();

      this.cancelToken = axios.CancelToken.source();

      const list = await this.CaseService.listCases(
        pagination,
        filter,
        assignment,
        filterFields,
        this.cancelToken.token
      );

      const overriddenCaseListOptions = sessionState.caseListOptions;
      overriddenCaseListOptions.filter = filter;
      if (list._embedded.cases.length > 0) {
        const gotoPayload: GotoCasePayload = {
          caseListPage: {
            number: 1,
            size: sessionState.caseListPage.size,
            totalElements: list.page.totalElements
          } as HalPageInfo['page'],
          cases: list._embedded.cases,
          caseListOptions: overriddenCaseListOptions
        };

        const firstCaseId = list._embedded.cases[0]['case-id'];
        const routeData = this.$router.resolve({ name: 'case', params: { id: firstCaseId } });
        const newWindowRef = window.open(routeData.href, '_blank');

        newWindowRef?.sessionStorage.setItem('gotoPayload', JSON.stringify(gotoPayload));
      } else {
        this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions('No case matches given case id');
      }
    }
    this.toolbarRef.goToMatchingCasesDone();
  }

  async onDropdownStatusChange(newStatus: CaseStatus) {
    this.statusUpdateState = UpdateState.Loading;

    this.newCaseStatus = newStatus;
    this.configModel = this.caseModel['status-history'].sort((x, y) =>
      x['created-at'] < y['created-at'] ? 0 : -1
    )[0];

    const metadataConfigForSelectedStatus = this.statusMetadataConfig.statuses.find(
      x => x.id === newStatus
    );
    if (
      metadataConfigForSelectedStatus?.metadata.length != 0 ||
      metadataConfigForSelectedStatus?.mandatoryNotes
    ) {
      this.showStatusConfigDialog = true;
    } else {
      const emptyMetadata: MetadataValuesModel = { metadata: [], note: '' };
      this.updateCaseStatus(newStatus, emptyMetadata);
    }
  }

  onDialogUpdate(model: MetadataValuesModel) {
    this.updateCaseStatus(this.newCaseStatus, model);
    this.showStatusConfigDialog = false;
  }

  statusKey = 0;
  onDialogCancel() {
    this.statusUpdateState = UpdateState.Default;
    this.showStatusConfigDialog = false;
    this.statusKey++;
  }

  async updateCaseStatus(newStatus: CaseStatus, metadata: MetadataValuesModel) {
    if (!this.caseId) {
      return Promise.resolve();
    }
    this.statusUpdateState = UpdateState.Loading;

    try {
      await this.StatusService.setCaseStatus(this.caseModel, newStatus, metadata, {
        willHandle: () => true
      });

      sessionState.SetStatus({ caseId: this.caseId, status: newStatus });
      this.statusUpdateState = UpdateState.Success;
      this.reloadCase(this.caseId);
      await delay(500);
      this.statusUpdateState = UpdateState.Default;
    } catch (thrown) {
      this.statusUpdateState = UpdateState.Error;

      if (typeof thrown === 'string') {
        this.statusError = thrown;
        return;
      }

      const response = (thrown as AxiosError).response;
      const error = response?.data.errors[0];
      if (response?.status === 409) {
        this.statusError = 'The case status has been modified.';
      } else {
        const message = error.message;
        this.statusError = message;
      }
    }
  }

  async updateAssignment(newUserId: string | null) {
    this.assignmentUpdateState = UpdateState.Loading;

    if (!this.caseId) {
      return Promise.resolve();
    }

    try {
      if (newUserId === null) {
        await this.UserService.unassignCase(this.caseModel, {
          willHandle: () => true
        });
      } else {
        await this.UserService.assignCaseToUser(this.caseModel, newUserId, {
          willHandle: () => true
        });
      }

      this.updateCaseAction(this.caseModel['case-id'], newUserId);
      this.assignmentUpdateState = UpdateState.Success;
      this.reloadCase(this.caseId);
      setTimeout(() => {
        this.assignmentUpdateState = UpdateState.Default;
      }, 500);
    } catch (thrown) {
      this.assignmentUpdateState = UpdateState.Error;

      if (typeof thrown === 'string') {
        this.assignmentError = thrown;
        return;
      }

      const response = (thrown as AxiosError).response;
      const error = response?.data.errors[0];
      if (response?.status === 409) {
        this.assignmentError = 'The assignee has been modified.';
      } else {
        const message = error.message;
        this.assignmentError = message;
      }
    }
  }

  async addNote(newNote: string) {
    await this.Notary.addNoteToCase(this.caseId, newNote);
    this.caseTabsRef.noteAdded();
  }

  async sendEmail(template: EmailTemplate, recipientEmail: string, message: string) {
    const additionalParameters = this.mapAdditionalTemplateRequirements(template);

    try {
      await this.EmailTemplateService.sendCaseEmail(
        this.caseId,
        template.id,
        recipientEmail,
        message,
        additionalParameters
      );
      snackbarMessaging.setSuccessMessage('Email successfully sent');
      this.reloadCase(this.caseId);
    } catch {
      snackbarMessaging.setUnsuccesfulMessage('Something went wrong sending the Email.');
    }
  }

  mapAdditionalTemplateRequirements(template: EmailTemplate) {
    const AdditionalParameters: Dictionary<Dictionary<string>> = {};

    if (template['include-hits']) {
      const key = 'Hits';
      const matchFields = this.caseModel.hits.flatMap(hit => {
        return hit.matches.map(match => match.field);
      });
      AdditionalParameters[key] = this.extractFromBooking(matchFields);
    }

    if (template['included-booking-properties'] && template['included-booking-properties'][0]) {
      const key = 'Details';
      AdditionalParameters[key] = this.extractFromBooking(
        template['included-booking-properties'],
        true
      );
    }
    return AdditionalParameters;
  }

  extractFromBooking(paths: string[], replacePeriods = false) {
    const mappedMatches: Dictionary<string> = {};

    paths.map(field => {
      const matchText = FindToken(field, this.booking);

      if (matchText) {
        let key = field;
        if (replacePeriods) {
          key = field.replace(/\./g, '-');
        }
        mappedMatches[key] = matchText as string;
      }
    });
    return mappedMatches;
  }

  onRefresh() {
    this.caseLoading = true;
    this.statusError = '';
    this.assignmentError = '';
    this.statusUpdateState = UpdateState.Default;
    this.assignmentUpdateState = UpdateState.Default;
    this.reloadCase();
  }

  closeTab() {
    window.close();
  }

  deselectHit() {
    this.caseTabsRef.deselectHit();
  }

  changedHighlightedText(matchView: MatchView) {
    this.caseTabsRef.changedHighlightedText(matchView);
  }

  async updateCaseAction(caseId: string, newUserId: string | null) {
    sessionState.SetNewUserId({ caseId, newUserId });
  }
}































































import {
  Vue, Component, Prop, Watch
} from 'vue-property-decorator';

import MapMarker from '@/components/material/map-marker.vue'
import RecursiveDetails from './recursive-details.vue';
import Highlight from "@/components/common/highlight-matches.vue";
import { MatchView } from '@/models/case-maintenance';
import { extractId } from './BookingFunc';


@Component({
  components: {
    MapMarker,
    RecursiveDetails,
    Highlight
  },
  inheritAttrs: false
})
export default class Sailings extends Vue {
  @Prop({ required: true }) booking: any;
  @Prop({ required: true }) path!: string;
  @Prop({ required: true }) node!: any;
  @Prop({ required: true }) matches!: MatchView[];

  expandedSailingsIndex = 0;
  selectedStage: any | null = null;
  selectedStageIndex =0;

  location(stage: any) {
    return stage.country?.code;
  }

  @Watch("$route", { immediate: true})
  setSelected(){
    if(!this.booking.sailings) 
      return;

    const path = this.$route.query.path as string;

    if(!path) return

    this.expandedSailingsIndex = this.getIndexOfPropertyFromPath(path, "sailings", this.booking.sailings)

    if(this.expandedSailingsIndex < 0) return
    if(!this.booking.sailings[this.expandedSailingsIndex]) return
    
    this.selectedStageIndex = this.getIndexOfPropertyFromPath(path, "stages", this.booking.sailings[this.expandedSailingsIndex].stages)

    this.selectedStage = this.booking
      .sailings[this.expandedSailingsIndex]
      .stages[this.selectedStageIndex]
  }

  getIndexOfPropertyFromPath(path: string, property: string, object: any){
    
    if(!object) return;

    const propertyIndex = this.getPropertyIndexFromPath(path, property)
    if(!isNaN(Number(propertyIndex))){
      return Number.parseInt(propertyIndex)
    }else{
      const id = extractId(propertyIndex);
      return object.findIndex((x: any) => x['$id'] == id);
    }
  }

  getPropertyIndexFromPath(path: string, name: string): string {

    const query = `${name}[`
    const startOfQuery =  path.indexOf(query)

    if(startOfQuery < 0) return "";

    const firstBracket = startOfQuery + query.length;
    const endOfQuery = path.indexOf("]", startOfQuery);
    const extractedIndex = path.substring(firstBracket, endOfQuery);

    if(extractedIndex) 
      return extractedIndex;

    return "";
  }

  toggleSelectedStage(stage: object, index: number, stageIndex: number) {
    if (this.selectedStage === stage) {
      this.selectedStage = null;
      this.expandedSailingsIndex = 0;
    } else {
      this.selectedStage = stage;
      this.expandedSailingsIndex = index;
      this.selectedStageIndex = stageIndex;
    }
  }

  
  getMatchesForSailings(prop: string, index: number): MatchView[] { 
    
    const mappedMatches = this.matches
      .map(match => {    
        return { 
          ...match, 
          mappedPath: this.replaceSailingsPathIndex(match)
        }
      })
   
    const pathFilter =  `$.sailings[${index}].${prop}`;
    
    return mappedMatches
      .filter(x => x.field.indexOf(pathFilter) == 0 || x.mappedPath.indexOf(pathFilter) == 0);
  }

  getMatchesForSailingStages(prop: string, index: number): MatchView[] { 
    
    const mappedMatches = this.matches
      .map(match => {      
        const mappedSailingsIndex =this. getIndexOfPropertyFromPath(match.field, "sailings", this.booking.sailings);
        let mappedStages = this.replaceIndexValue(match.field, 'sailings', mappedSailingsIndex)

        if(this.booking.sailings[mappedSailingsIndex].stages && match.field.includes('stages'))
        {
          const mappedStagesIndex = this.getIndexOfPropertyFromPath(mappedStages, "stages", this.booking.sailings[mappedSailingsIndex].stages)
          mappedStages = this.replaceIndexValue(mappedStages, 'stages', mappedStagesIndex)
        }
       
        return { 
          ...match, 
          mappedPath: mappedStages
        }
      })

    const pathFilter = `$.sailings[${index}].stages[${this.selectedStageIndex}].${prop}`;

    return mappedMatches
      .filter(x => x.field.indexOf(pathFilter) == 0 || x.mappedPath.indexOf(pathFilter) == 0);
  }

  replaceSailingsPathIndex(match: MatchView){
    const mappedSailingsIndex =this. getIndexOfPropertyFromPath(match.field, "sailings", this.booking.sailings);
    return this.replaceIndexValue(match.field, 'sailings', mappedSailingsIndex)
  }

  replaceIndexValue(path: string, property: string, newValue: number): string {
    
    const query = `${property}[`
    const startOfQuery =  path.indexOf(query)

    if(startOfQuery < 0) return "";

    const firstBracket = startOfQuery + query.length;
    const endOfQuery = path.indexOf("]", startOfQuery);

    const before = path.slice(0, firstBracket);
    const end = path.slice(endOfQuery, path.length)

    return before+ newValue + end;
  }
}


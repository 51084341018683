









































































import { HitView, MatchLocation, MatchView } from "@/models/case-maintenance";
import { JSONPath } from "jsonpath-plus";
import { Component, Prop, Vue } from "vue-property-decorator";
import { calculatePath, getSubPath } from "@/components/booking/BookingFunc";

@Component
export default class HitDetails extends Vue {
  @Prop({ required: true }) hit!: HitView;

  @Prop({ required: true }) booking!: any;

  get metadataItems() {
    return Object.keys(this.hit.metadata)
      .filter(key => this.hit.metadata[key])
      .map(key => `${key}: ${this.hit.metadata[key]}`);
  }

  emitSelectedMatch(matchView: MatchView) {
    this.$emit("selected-match", matchView);
  }

  navToHitInBooking(matchView: MatchView) {
    this.emitSelectedMatch(matchView);
    const path = calculatePath(matchView.field);
    this.$router.replace({ query: { path: path, tabToSelect: getSubPath(matchView.field) } }).catch(() => true);
  }

  container(match: MatchView): string | undefined {
    let result = undefined;
    if (HitDetails.locationMatches(match.location, "cargo-transport-units")) {
      result = match.location[0].indexDescription;
    }
    return result;
  }

  matchDescription(match: MatchView): string {
    if (
      HitDetails.locationMatches(
        match.location,
        "cargo-transport-units",
        "cargo",
        "packaging",
        "description"
      )
    )
      return `Packaging description ${HitDetails.cargoDescription(match, this.booking)}`;
    if (HitDetails.locationMatches(match.location, "cargo-transport-units", "cargo", "description"))
      return HitDetails.cargoDescription(match, this.booking);

    if (HitDetails.locationMatches(match.location, "cargo-transport-units", "cargo"))
      return HitDetails.cargoField(match);

    if (HitDetails.locationMatches(match.location, "cargo-transport-units"))
      return HitDetails.containerDescription(match);

    if (HitDetails.locationMatches(match.location, "description")) return "booking description";

    return match.field;
  }

  static cargoDescription(match: MatchView, booking: any): string {
    let description = match.location[2].indexDescription;
    if (booking) {
      const jsonPath = JSONPath({
        path: HitDetails.substringBeforeLast(match.field, ".") + ".source",
        json: booking
      })[0];

      if (jsonPath) {
        description = jsonPath;
      }
    }

    const cargo = match.location[1].indexDescription;
    const container = match.location[0].indexDescription;
    return `${description} of cargo ${cargo} in container ${container}`;
  }

  static substringBeforeLast(input: string, separator: string) {
    const lastIndex = input.lastIndexOf(separator);
    return input.substring(0, lastIndex);
  }

  static cargoField(match: MatchView): string {
    const field = match.location[2].indexDescription;
    const cargo = match.location[1].indexDescription;
    const container = match.location[0].indexDescription;
    return `'${field}' of cargo ${cargo} in container ${container}`;
  }

  static containerDescription(match: MatchView): string {
    const container = match.location[0].indexDescription;
    return `container ${container}`;
  }

  static locationMatches(location: MatchLocation[], ...path: string[]): boolean {
    if (path.length > location.length) return false;

    for (let i = 0; i < path.length; i++) {
      if (location[i].key != path[i]) return false;
    }

    return true;
  }
}

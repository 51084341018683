




import { Component, Prop, Vue } from 'vue-property-decorator';
import Highlight from '@/components/common/highlight-matches.vue';
import { MatchView } from '@/models/case-maintenance';

@Component({components: {
    Highlight
}})
export default class BookingDetailCargoPropertyDefault extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: true }) matches!: MatchView[]
  @Prop({ required: true }) propertyName!: string

  get highlightedHits(){
    return this.matches.filter(x=>x.field.includes(this.propertyName))
  }
}

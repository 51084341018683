

































import { Component, Prop, Vue } from 'vue-property-decorator';

import { CaseView } from '@/models/case-maintenance';
import { ScreenResult } from '@/models/case-maintenance.d';
import UserControl from '@/components/user.vue';
import CaseHistoryItem from '@/components/case-history/case-history-item.vue';
import CaseHistoryBooking from './case-history-booking.vue';

@Component({
  components: {
    User: UserControl,
    CaseHistoryItem,
    CaseHistoryBooking
  }
})
export default class CaseHistoryScreening extends Vue {
  @Prop({ required: true }) data!: ScreenResult
  @Prop({ required: true }) model!: CaseView

  dialogOpen = false;

  get isFirstScreening(): boolean {
    const firstScreening = [...this.model['screen-results']]
      .sort((a, b) => a['screening-date'] > b['screening-date'] ? 1 : -1)[0];

    return firstScreening['screening-id'] === this.data['screening-id'];
  }

  get icon() {
    if(this.data.icon) return this.data.icon

    return { color: "error", label: "Unknown"}
  }

}








import { Component, Inject, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import UserService from '@/services/user-service';

@Component
export default class UserControl extends Vue {
  @Inject() UserService!: UserService;

  @Prop({ default: null }) userId!: string;

  name = "";

  icon = "mdi-account";

  @Watch('userId', { immediate: true })
  async updateName() {
    if (this.userId === 'system') {
      this.name = 'Hazcheck Detect'
      this.icon = 'mdi-settings'
    } else {
      this.name = await this.UserService.getName(this.userId);
    }
  }
}

import { Hit } from "@/models/case-maintenance.d";
import { ScreenResult } from "@/models/case-maintenance.d";
import { EditState, HitView } from "@/models/case-maintenance";

export function screenResultsToHitView(screenResult: ScreenResult[]): HitView[] {
    const rules = screenResult
      .flatMap(x => x.hits.map(hit => ({ hit, date: x["screening-date"] })))
      .reduce((a, b) => {
        const ruleId = b.hit.rule.id;
        if (!(ruleId in a) || a[ruleId].date < b.date) {
          a[ruleId] = b;
        }
        return a;
      }, {} as { [key: string]: { hit: Hit; date: Date } });



    const hits = Object.values(rules);
    return hits.map(x => new HitView(
      x.hit, 
      true,
      EditState.none
    ));
  }
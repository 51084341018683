
















import { Component, Prop, Vue } from "vue-property-decorator";
import { Match } from "@/models/case-maintenance.d";
import Highlight from "@/components/common/highlight-matches.vue";

@Component({
  components: {
    Highlight
  }
})
export default class BookingDetailCargoDescriptionsComponent extends Vue {
  @Prop({ required: true }) cargo!: any;
  @Prop({ required: true }) matches!: Match[];
  @Prop({ required: true }) path!: string;

  hasCode(description: any) {
    return description.code;
  }

  pathForDescription(description: any, index: number): string[] {
    const paths = [];

    paths.push(`${this.path}.description[${index}]`);

    if ("$id" in description) {
      paths.push(`${this.path}.description[?(@.$id == '${description.$id}')]`);
    }

    if ("source" in description) {
      paths.push(`${this.path}.description[?(@.source == '${description.source}')]`);
    }

    return paths;
  }

  matchesForDescription(description: any, index: number): Match[] {
    const paths = this.pathForDescription(description, index);
    return this.matches.filter(x => paths.filter(p => x.field.startsWith(p)).length > 0);
  }
}
























import { Vue, Component, Prop } from "vue-property-decorator";
import Skeleton from "../common/skeleton.vue";

@Component({
  components: {
    Skeleton
  }
})
export default class CaseDetails extends Vue {
  @Prop({ default: true }) loading?: boolean;
  @Prop({ default: false }) caseDetailsLoaded?: boolean;
  @Prop({ default: [] }) caseDetails?: { name: string; value: string }[];
}



















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class SelectWithStatus extends Vue {
  @Prop({ default: "default" }) state!: "default" | "loading" | "success" | "error";

  @Prop({ default: "v-select" }) component!: string;

  @Prop({ default: "" }) errorMessage!: string | null;

  @Prop({ default: false }) disabled!: boolean;

  hover = false;

  get disableToolTip() {
    return !this.errorMessage?.length;
  }
}

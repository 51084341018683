




































import { CaseView } from '@/models/case-maintenance';
import UserControl from '@/components/user.vue';
import {
  Component, Prop, PropSync, Vue
} from 'vue-property-decorator';

@Component({
  components: {
    User: UserControl
  }
})
export default class CaseNotes extends Vue {
  @Prop({ required: true }) model!: CaseView

  @PropSync('value') syncedValue!: string;

  @Prop() loading!: boolean;

  async addNote() {
    this.$emit('create-note', this.syncedValue);
  }
}

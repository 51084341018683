  const query = "?(";
  const field = "@.";

  function reconstructPropertyFilters(pathParts: string[]) {
    const reconstructedParts: string[] = [];
    let openBraces = false;
    pathParts.forEach(part => {
      if (!part.includes("[") && !openBraces) {
        reconstructedParts.push(part);
      }
      else
      {
        if(openBraces)
        {
          const currentIndex = reconstructedParts.length - 1;
          reconstructedParts[currentIndex] = `${reconstructedParts[currentIndex]}.${part}`;
          openBraces = true;
        }else{
          openBraces = true;
          reconstructedParts.push(part);        
        }
        
        if (part.includes("]")) {
          openBraces = false;
        }
      }
      
    });
    return reconstructedParts;
  }

  function splitPath(path: string) {
    const pathParts = path.split(".")
    return reconstructPropertyFilters(pathParts);
  }

  function keyIsValid(key: string) {
    if(!key) return false;
    if(key === "") return false;
    if(key === "$") return false;
    return true;
  }

  function getParametersFromQueryString(key: string): { propertyName: string; propertyValue: string } {
    
    const cleansePropertyKey = key
      .replace(query, "")
      .replace(field, "")
      .replace(")]", "")
      .replace(/'/g, "")
      .trim();

    const equalsOperator =  "==";
    const propertyFilter = cleansePropertyKey.split(equalsOperator)
    const propertyName = propertyFilter[0].trim();
    const propertyValue = propertyFilter[1].trim();
      
    return {propertyName, propertyValue}
  }
  
  function ExtractValuesByProperty(keyParts: string[], value: any, object: any) {
    const key = keyParts[0];

    const { propertyName, propertyValue} =  getParametersFromQueryString(keyParts[1]);
   
    value = object[key].find((x: any) => x[propertyName] == propertyValue)

    return { key, value }
  }

  function ExtractValueUsingIndex(key: string, value: any, object: any) {
    const keyParts = key.split("[");
    key = keyParts[0];

    if(keyParts[1].includes(query)){
     return ExtractValuesByProperty(keyParts, value, object)
    }

    const index = keyParts[1].replace("]", "");
    value = object[key][Number.parseInt(index)];
    return { key, value };
  }

  export default function FindToken(path: string, object: any): string | undefined {
    
    const parts = splitPath(path)

    for(let i = 0; i < parts.length; i++){
      let key = parts[i];

      if(!keyIsValid(key)) continue;

      let value = undefined;

      if(key.includes("[")){
        ({ key, value } = ExtractValueUsingIndex(key, value, object));
      }
      else
      {
        value = object[key]; 
      }

      if(!value) return;

      object = value;
    }

    return object;
  }
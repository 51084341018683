





















import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import config from "@/config";

import SelectWithStatus from "@/components/material/select-with-status.vue";

@Component({
  components: {
    SelectWithStatus
  },
  inheritAttrs: false
})
export default class StatusControl extends Vue {
  @Prop() caseId!: string;
  @Prop() showEditIcon!: boolean;
  items = config.caseStatus;

  editButtonClicked() {
    this.$emit("editButtonClicked");
  }
}




































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import BookingDetail from "./booking-detail.vue";
import BookingHeader from "./booking-header.vue";
import { HitView } from "@/models/case-maintenance";
import {
  BookingNode,
  getBasePath,
  getBookingNodes,
  getSingleQueryParam
} from "@/components/booking/BookingFunc";

@Component({
  components: {
    BookingDetail,
    BookingHeader
  },
  inheritAttrs: false
})
export default class Booking extends Vue {
  @Prop({ required: true }) booking!: any;
  @Prop({ required: true }) hits!: HitView[] | null;
  @Prop({ required: true }) highlightSelected!: boolean;
  @Prop({ default: false, required: false }) disableUrlUpdates!: boolean | null;

  active: string[] = [];
  bookingNodes: BookingNode[] = [];

  get currentHits(): HitView[] {
    if (!this.hits) return [];

    const current = this.hits.filter(x => x.isCurrent);

    if (this.highlightSelected) {
      const selectedMatchesWithHits = current.map(hit => {
        return { ...hit, matches: hit.matches.filter(match => match.singleHighlight) };
      });

      return selectedMatchesWithHits;
    }

    return current;
  }

  get currentPath(): string {
    return this.active.length > 0 ? this.active[0] : "";
  }

  @Watch("booking", { immediate: true })
  bookingChanged() {
    this.bookingNodes = getBookingNodes(this.booking);
  }

  @Watch("$route", { immediate: true })
  pathChanged() {
    this.loadTreeview();
  }

  loadTreeview() {
    this.active = [];
    const path = getSingleQueryParam(this.$route.query.path, "");
    const basePath = getBasePath(path);
    this.active.push(basePath);
  }

  treeViewClicked() {
    if (this.disableUrlUpdates) return;
    if (this.$route.query.tabToSelect != "0") {
      const prevPath = getSingleQueryParam(this.$route.query.path, "");
      this.$router.replace({ query: { path: prevPath, tabToSelect: "0" } });
    }
  }

  treeViewUpdated(event: any[]) {
    if (this.disableUrlUpdates) return;
    if (event.length > 0) {
      const queryPath = this.$route.query.path;
      const prevTabToSelect = this.$route.query.tabToSelect ?? "0";

      if (!queryPath) {
        this.$router
          .replace({ query: { path: event[0] ?? null, tabToSelect: prevTabToSelect } })
          .catch(() => {
            return true;
          });
        return;
      }

      if (!queryPath.toString().startsWith(event[0])) {
        this.$nextTick(() => {
          const prevPath = getSingleQueryParam(this.$route.query.path, "");
          if (event.length > 0 && prevPath !== event[0]) {
            this.$router
              .replace({ query: { path: event[0] ?? null, tabToSelect: prevTabToSelect } })
              .catch(() => {
                return true;
              });
          }
        });
      }
    }
  }
}

















import { Component, Prop, Vue } from 'vue-property-decorator';
import Highlight from '@/components/common/highlight-matches.vue';
import { Match } from '@/models/case-maintenance.d';

@Component({
  components: {
    Highlight
  }
})
export default class BookingDetailCargoPropertyPackaging extends Vue {
  @Prop({ required: true }) value!: any;
  @Prop({ required: true }) matches!: Match[];

  matchesForDescription(field: string){
    return this.matches.filter(x=> this.endsWithPackageing(x.field, field));
  }

  endsWithPackageing(path: any, fieldName: string){

      const splitPath = path.split('.');   
      if(splitPath.length > 0){
        const last = splitPath[splitPath.length-1]
        const secondToLast = splitPath[splitPath.length - 2]

        return secondToLast === 'packaging' && last === fieldName
         
      }
     return false;
  }


}







































































































































































import { Component, Vue, Inject, Watch, Ref } from 'vue-property-decorator';
import Card from '@/components/material/Card.vue';
import { backOr } from '@/router';
import { EmailTemplate } from '@/models/email-templates';
import EmailTemplateService from '@/services/email-template-service';
import EcSnackBar from '@/components/common/ec-snackbar.vue';
import * as snackbarMessaging from '@/helpers/snackbarMessaging';
import BoundModel from '@/helpers/modelBinding';
import EmailTemplateDeleteDialog from '@/components/email-templates/email-template-delete-dialog.vue';

@Component({
  components: {
    Card,
    EcSnackBar,
    EmailTemplateDeleteDialog
  }
})
export default class EmailTemplateView extends Vue {
  @Inject() EmailTemplateService!: EmailTemplateService;

  @Ref() form!: HTMLFormElement;
  @Ref() dialogForm!: HTMLFormElement;

  boundModel: BoundModel<EmailTemplate> = new BoundModel<EmailTemplate>();
  dialog = false;
  recipient = '';
  message = '';
  confirmDeleteDialog = false;
  mailList = '';
  includeBookingProperties = '';

  get model() {
    return this.boundModel.get();
  }

  get isEditing() {
    return this.emailTemplateId != null;
  }

  emailTemplateId?: string;

  back() {
    backOr({ name: 'email-templates' });
  }

  sending = false;

  @Watch('$route', { immediate: true })
  async onRouteChanged(value: any): Promise<void> {
    this.emailTemplateId = value.params.id;

    if (!this.emailTemplateId) return;

    await this.loadData();
  }

  async loadData() {
    if (this.emailTemplateId == null) return;

    const apiResult = await this.EmailTemplateService.readSingle(this.emailTemplateId);

    this.boundModel.setNew(apiResult);
    this.mailList = this.model['mail-list'].reduce((a, b) => (a = `${a}, ${b}`));
    this.includeBookingProperties = this.model['included-booking-properties'].reduce(
      (a, b) => (a = `${a}, ${b}`)
    );
  }

  reset() {
    this.boundModel.reset();
  }

  tryEmail() {
    this.dialog = true;
    this.recipient = '';
    this.message = '';
  }

  closeDialog() {
    this.dialog = false;
    this.recipient = '';
    this.message = '';
    this.sending = false;
  }

  async sendEmail() {
    this.sending = true;
    if (!this.dialogForm.validate()) return;
    try {
      await this.EmailTemplateService.sendTestEmail(
        this.recipient,
        this.model.subject,
        this.model.body,
        this.message
      );
      snackbarMessaging.setSuccessMessage('Test Email successfully sent');
      this.closeDialog();
    } catch {
      snackbarMessaging.setUnsuccesfulMessage('Something went wrong sending the Test Email.');
    } finally {
      this.sending = false;
    }
  }

  openDeleteEmailTemplateDialog() {
    this.confirmDeleteDialog = true;
  }

  async save() {
    if (!this.form.validate()) return;

    try {
      this.model['mail-list'] = this.splitAndTrim(this.mailList);
      this.model['included-booking-properties'] = this.splitAndTrim(this.includeBookingProperties);

      if (this.emailTemplateId) {
        snackbarMessaging.setProgressMessage('Saving Email Template');
        const result = await this.EmailTemplateService.updateEmailTemplate(
          this.emailTemplateId,
          this.model
        );
        snackbarMessaging.setSuccessMessage('Successfully updated');
        this.boundModel.setNew(result);
      } else {
        snackbarMessaging.setProgressMessage('Creating Email Template');
        const result = await this.EmailTemplateService.createEmailTemplate(this.model);
        snackbarMessaging.setSuccessMessage('Successfully created');
        this.$router.replace({ name: 'email-template', params: { id: result.id } });
      }
    } catch (http) {
      snackbarMessaging.setDefaultMessage();
    }
  }

  splitAndTrim(array: string): string[] {
    return array.split(',').map(x => x.trim());
  }

  async deleteEmailTemplate() {
    this.confirmDeleteDialog = false;

    try {
      await this.EmailTemplateService.delete(this.emailTemplateId ?? '');
      this.back();
    } catch (error) {
      snackbarMessaging.setUnsuccesfulMessage(error as string);
    }
  }
}












































import { caseTabs, highlightSelected } from "@/views/CaseFunc";
import { Vue, Component, Prop, PropSync } from "vue-property-decorator";
import CaseHits from "@/components/case/case-hits.vue";
import { CaseView, MatchView } from "@/models/case-maintenance";
import Booking from "../booking/booking.vue";
import { CaseTab } from "@/views/Case.d";
import CaseNotes from "./case-notes.vue";
import CaseHistory from "../case-history/case-history.vue";

@Component({
  components: {
    Booking,
    CaseHits,
    CaseNotes,
    CaseHistory
  }
})
export default class CaseTabs extends Vue {
  @PropSync('activeCaseTab') activeCaseTabSync?: number;
  @Prop({}) showTabs?: boolean;
  @PropSync('caseModel') caseModelSync?: CaseView;
  @Prop({}) booking?: any;

  get caseId(): string {
    return this.caseModelSync ? this.caseModelSync["case-id"] : "";
  }

  get caseTabs(): CaseTab[] {
    return caseTabs;
  }

  get highlightSelected(): boolean {
    return highlightSelected(this.caseModelSync);
  }

  get hitsLoaded(): boolean {
    return this.caseModelSync ? this.caseModelSync?.hits?.length > 0 : false;
  }

  get screeningId(): string {
    return this.caseModelSync ? this.caseModelSync["screen-results"][0]["screening-id"] : "";
  }

  changedHighlightedText(matchView: MatchView) {
    if (this.caseModelSync) {
      for (const hit of this.caseModelSync.hits) {
        for (const match of hit.matches) {
          match.singleHighlight = false;
        }

        const hitIndex = this.caseModelSync.hits.indexOf(hit);
        const filtered = hit.matches.indexOf(matchView);
        if (filtered === -1) continue;
        this.caseModelSync.hits[hitIndex].matches[filtered].singleHighlight = true;
      }
    }
  }

  deselectHit() {
    if (this.caseModelSync) {
      this.caseModelSync.hits = this.caseModelSync.hits.map(hit => {
        return {
          ...hit,
          matches: hit.matches.map(match => {
            return { ...match, singleHighlight: false };
          })
        };
      });
    }
  }

  newNote = "";
  addNoteLoading = false;

  addNote() {
    this.addNoteLoading = true;
    this.$emit('add-note', this.newNote);
  }

  noteAdded() {
    this.newNote = "";
    this.addNoteLoading = false;
    this.$emit('reload-case', this.caseId);
  }

  refreshHistory() {
    this.$emit('reload-case');
  }
}










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditState, HitView } from '@/models/case-maintenance';
import ContainerSummary from './container-summary.vue';
import HitDetails from './hit-details.vue';

@Component({
  components: {
    ContainerSummary,
    HitDetails,
  }
})
export default class CaseHit extends Vue {
  @Prop({ required: true }) hit!: HitView;

  @Prop({ required: true }) booking!: any;

  metadataItems() {
    return Object.keys(this.hit.metadata)
      .filter(key => this.hit.metadata[key])
      .map(key => `${key}: ${this.hit.metadata[key]}`)
  }

  isNew(hit: HitView): boolean 
  {
    return hit.editState == EditState.new;
  }

  isNone(hit: HitView): boolean 
  {
    return hit.editState == EditState.none;
  }

  isEdited(hit: HitView): boolean {
    return hit.editState == EditState.edited;
  }
}

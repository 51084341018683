import store, { hasState } from '@/store';
import {
  Module,
  VuexModule,
  Mutation,
  MutationAction,
} from 'vuex-module-decorators';
import config from '@/config';
import { HalPageInfo, PaginationOptions } from '@/models/hal';
import { CaseView } from '@/models/case-maintenance';
import { Filter } from '@/models/filters';
import {
  CaseListOptions,
  MatchListOptions,
  Query,
} from '@/models/session-state-types';
import { CaseStatus } from '@/models/case-status';

const shouldLoadState = hasState('session-state');

@Module({
  dynamic: true,
  store,
  name: 'session-state',
  preserveState: shouldLoadState,
})
export default class SessionState extends VuexModule {
  _defaultPageSize = 20;
  _cases: CaseView[] = [];
  _caseListQuery: Query = {};
  _caseListPage: HalPageInfo['page'] = {
    number: 1,
    size: 20,
    totalElements: 0,
  };
  _caseIndex = 0;

  _caseListPagination: PaginationOptions = {};
  _caseListFilter: Filter[] = [];
  _caseListAssignment: string | null = null;

  get defaultPageSize(): number {
    return this._defaultPageSize ?? config.defaultPageSize;
  }

  get case(): CaseView {
    return this._cases[this._caseIndex];
  }

  get casePosition(): number {
    return (
      (this._caseListPage.number - 1) * this._caseListPage.size +
      (this._caseIndex + 1)
    );
  }

  get cases(): CaseView[] {
    return this._cases;
  }

  get caseListQuery(): Query {
    return this._caseListQuery;
  }

  get caseListPage(): HalPageInfo['page'] {
    return this._caseListPage;
  }

  get caseListAssignment(): string | null {
    return this._caseListAssignment;
  }

  get caseListOptions(): CaseListOptions {
    return {
      options: this._caseListPagination,
      filter: this._caseListFilter,
      assignment: this._caseListAssignment,
      query: this._caseListQuery,
    } as CaseListOptions;
  }

  @Mutation
  setCaseListQuery(query: Query) {
    this._caseListQuery = query;
  }

  @Mutation
  setDefaultPageSize(size?: number) {
    this._defaultPageSize = size ?? 20;
  }

  @Mutation
  setCases(cases: CaseView[]) {
    this._cases = cases;
  }

  @Mutation
  setCaseListOptions(options: CaseListOptions) {
    this._caseListPagination = options.options;
    this._caseListFilter = options.filter;
    this._caseListAssignment = options.assignment;
    this._caseListQuery = options.query;
  }

  @Mutation
  setMatchListOptions(options: MatchListOptions) {
    this._caseListPagination = options.options;
    this._caseListFilter = options.filter;
    this._caseListQuery = options.query;
  }

  @Mutation
  setCaseListPage(page: HalPageInfo['page']) {
    this._caseListPage = page;
  }

  static async navigatePage(
    {
      isWithinCurrentPage,
      isAtPageBoundary,
      navigate,
      newPageIndex,
    }: {
      isWithinCurrentPage: (state: SessionState) => boolean;
      isAtPageBoundary: (pageInfo: HalPageInfo['page']) => boolean;
      navigate: (current: number) => number;
      newPageIndex: (pageInfo: HalPageInfo['page']) => number;
    },
    state: SessionState,
    getters: SessionState
  ) {
    if (isWithinCurrentPage(state)) {
      return {
        _cases: state._cases,
        _caseIndex: navigate(state._caseIndex),
        _caseListPagination: state._caseListPagination,
        _caseListPage: state._caseListPage,
      };
    }

    const pageInfo = getters.caseListPage;

    if (isAtPageBoundary(pageInfo)) {
      return {
        _cases: state._cases,
        _caseIndex: state._caseIndex,
        _caseListPagination: state._caseListPagination,
        _caseListPage: state._caseListPage,
      };
    }

    const newPagination = {
      ...state._caseListPagination,
      page: navigate(pageInfo.number),
    };

    const caseService = store.services.CaseService;

    const results = await caseService.listCases(
      newPagination,
      state._caseListFilter,
      state._caseListAssignment
    );

    return {
      _cases: results._embedded.cases,
      _caseIndex: newPageIndex(results.page),
      _caseListPagination: newPagination,
      _caseListPage: results.page,
    };
  }

  @MutationAction({
    mutate: ['_cases', '_caseIndex', '_caseListPagination', '_caseListPage'],
  })
  async nextCase() {
    return await SessionState.navigatePage(
      {
        isWithinCurrentPage: (state) =>
          state._caseIndex < state._cases.length - 1,
        isAtPageBoundary: (pageInfo) =>
          pageInfo.number == Math.ceil(pageInfo.totalElements / pageInfo.size),
        navigate: (page) => page + 1,
        newPageIndex: () => 0,
      },
      (this.state as unknown) as SessionState,
      (this.getters as unknown) as SessionState
    );
  }

  @MutationAction({
    mutate: ['_cases', '_caseIndex', '_caseListPagination', '_caseListPage'],
  })
  async previousCase() {
    return await SessionState.navigatePage(
      {
        isWithinCurrentPage: (state) => state._caseIndex > 0,
        isAtPageBoundary: (pageInfo) => pageInfo.number == 1,
        navigate: (page) => page - 1,
        newPageIndex: (pageInfo) => pageInfo.size - 1,
      },
      (this.state as unknown) as SessionState,
      (this.getters as unknown) as SessionState
    );
  }

  static async reloadCaseUnknowId(state: SessionState) {
    if (state._cases.length) {
      const caseId = state._cases[state._caseIndex]['case-id'];
      const caseService = store.services.CaseService;
      const result = await caseService.readSingle(caseId);

      return {
        _cases: [
          ...state._cases.slice(0, state._caseIndex),
          result,
          ...state._cases.slice(state._caseIndex + 1),
        ],
        _caseIndex: state._caseIndex,
        _caseListPagination: state._caseListPagination,
        _caseListPage: state._caseListPage,
      };
    } else {
      return {
        _cases: state._cases,
        _caseIndex: state._caseIndex,
        _caseListPagination: state._caseListPagination,
        _caseListPage: state._caseListPage,
      };
    }
  }

  static async reloadCaseId(caseId: string, state: SessionState) {
    const foundCaseIndex = state._cases.findIndex(
      (x) => x['case-id'] == caseId
    );
    const caseService = store.services.CaseService;
    const result = await caseService.readSingle(caseId);

    if (foundCaseIndex > -1) {
      return {
        _cases: [
          ...state._cases.slice(0, foundCaseIndex),
          result,
          ...state._cases.slice(foundCaseIndex + 1),
        ],
        _caseIndex: foundCaseIndex,
        _caseListPagination: state._caseListPagination,
        _caseListPage: state._caseListPage,
      };
    } else {
      return {
        _cases: [result],
        _caseIndex: 0,
        _caseListPagination: { page: 1, size: 20, sort: [] },
        _caseListPage: { page: 1, size: 20, totalElements: 1 },
      };
    }
  }

  static async reloadCase(caseId: string | undefined, state: SessionState) {
    if (!caseId) {
      return await this.reloadCaseUnknowId(state);
    }
    return await this.reloadCaseId(caseId, state);
  }

  @MutationAction({
    mutate: ['_cases', '_caseIndex', '_caseListPagination', '_caseListPage'],
  })
  async reloadCase(caseId?: string) {
    return SessionState.reloadCase(
      caseId,
      (this.state as unknown) as SessionState
    );
  }

  @Mutation
  SetNewUserId(payload: { caseId: string; newUserId: string | null }) {
    const { caseId, newUserId } = payload;

    const caseToUpdate = this._cases.find(
      (x: CaseView) => x['case-id'] === caseId
    ) as CaseView;

    if (caseToUpdate) {
      caseToUpdate['assigned-to'] = newUserId;
    }
  }

  @Mutation
  SetStatus(payload: { caseId: string; status: CaseStatus }) {
    const { caseId, status } = payload;

    const caseToUpdate = this._cases.find(
      (x: CaseView) => x['case-id'] === caseId
    ) as CaseView;

    if (caseToUpdate) {
      caseToUpdate['status'] = status;
    }
  }
}

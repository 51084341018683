


















import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({
})
export default class CaseCreated extends Vue {
  @Prop() createdAt?: Date;
}

















import { Vue, Component, Prop } from "vue-property-decorator";
import { MatchView, HitView } from "@/models/case-maintenance";
import Highlight from "@/components/common/highlight-matches.vue";

@Component({
  components: {
    Highlight
  }
})
export default class BookingDetailParties extends Vue {
  @Prop({ required: true }) booking: any;
  @Prop({ required: true }) hits!: HitView[];

  headingDetails() {
    const headerDetails: { key: string; value: string; long: boolean }[] = [];
    const excludedKeys = ["imdg-amendment"];

    for (const key in this.booking) {
      if (key[0] === "$" || excludedKeys.indexOf(key) >= 0) {
        continue;
      }

      const value = this.booking[key];

      if (!value || typeof value === "object") {
        continue;
      }

      const long = typeof value === "string" && value.length > 100;
      headerDetails.push({ key, value, long });
    }

    headerDetails.sort((a, b) => (a.long > b.long ? 1 : -1));
    return headerDetails;
  }

  matchesForDescription(label: string): MatchView[] {
    const matches = this.hits.flatMap(x => x.matches);
    return matches.filter(x => x.field === `$.${label}`);
  }
}

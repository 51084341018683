















import { CaseStatusEntry } from '@/models/case-maintenance.d'
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

@Component
export default class CaseHistoryStatusDetails extends Vue {
 @Prop({ required: true }) data!: CaseStatusEntry
 @PropSync("metadialogOpen", {required: true}) dialogOpen!: boolean
}

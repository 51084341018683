export default class BoundModel<T> {
    private currentModel: T = {} as T;
    private originalModel: T = {} as T;

    get() {
        return this.currentModel;
    }

    setNew(newValue: T) {
        this.currentModel = newValue;
        this.originalModel = { ...newValue };
    }

    reset(): void {
        this.currentModel = { ...this.originalModel };
    }
}
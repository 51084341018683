import { CaseDetail, CaseTab } from '@/views/Case.d';
import { CaseView } from "@/models/case-maintenance";
import { distinct } from "@/helpers/distinct";
import config, { CaseListSource } from "@/config";

function getDetail(key: string, caseModel: CaseView) {
  if (!caseModel || !caseModel["case-details"]) return;

  return caseModel["case-details"]
    .filter(x => x.key == key)
    .map(x => x.value)
    .filter(distinct)
    .sort()
    .join(", ");
}

export function getCaseDetails(caseModel: CaseView): CaseDetail[] {
  return config.caseList
    .filter(x => x.source == CaseListSource.CaseDetail)
    .filter(x => x.type !== "link")
    .map(x => ({ name: x.name, value: getDetail(x.key, caseModel) }))
    .filter(x => x.value);
}

export function highlightSelected(caseModel: CaseView | undefined): boolean {
  return caseModel ?
  caseModel?.hits?.filter(
      hit => hit.matches.filter(match => match.singleHighlight).length > 0
    ).length > 0
  : false;
}

export const caseTabs: CaseTab[] = [
  { id: 0, name: "Hits" },
  { id: 1, name: "Booking" },
  { id: 2, name: "Notes" },
  { id: 3, name: "History" }
];

export enum UpdateState {
  Default = 'default',
  Loading = 'loading',
  Success = 'success',
  Error = 'error'
}

























  import { Vue } from "vue-property-decorator";
  import { Prop } from "vue-property-decorator";
  import Component from "vue-class-component";
import { UpdateState } from "@/views/CaseFunc";

  @Component({
    components: {}
  })
  export default class CaseNavigation extends Vue {
    @Prop({}) currentCaseNumber?: number;
    @Prop({}) caseListLength?: number;
    @Prop({}) statusUpdateState?: UpdateState;
    @Prop({}) assignmentUpdateState?: UpdateState;
    @Prop({}) caseIsLoading?: UpdateState;

    get shouldNotBeAbleToNavigateLeft() {
      return (
        this.currentCaseNumber === 1 ||
        this.isLoading()
      );
    }
    get shouldNotBeAbleToNavigateRight() {
      return (
        this.currentCaseNumber === this.caseListLength ||
        this.isLoading()
      );
    }

    goToCase(value: number) {
      this.$emit('goto-case', value);
    }

    isLoading() {
     return this.statusUpdateState === UpdateState.Loading ||
        this.assignmentUpdateState === UpdateState.Loading ||
        this.caseIsLoading
    }
  }






import { MatchView } from "@/models/case-maintenance";
import { Vue, Component, Prop } from "vue-property-decorator";
import Highlight from "@/components/common/highlight-matches.vue";

@Component({
  components: {
    Highlight
  },
  inheritAttrs: false
})
export default class BookingDetailPartiesSubtitle extends Vue {
  @Prop({ required: true }) prop?: string;
  @Prop({ required: true }) propValue?: string;
  @Prop({ required: true }) matches!: MatchView[];
  @Prop({ required: true }) path?: string;
}

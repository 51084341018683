



























import { MatchView } from "@/models/case-maintenance";
import { Vue, Component, Prop } from "vue-property-decorator";
import BookingDetailPartiesTitle from "@/components/booking/booking-detail-parties-title.vue";
import BookingDetailPartiesSubtitle from "@/components/booking/booking-detail-parties-subtitle.vue";

@Component({
  inheritAttrs: false,
  components: {
    BookingDetailPartiesTitle,
    BookingDetailPartiesSubtitle
  }
})
export default class BookingDetailParties extends Vue {
  @Prop({ required: true }) booking: any;
  @Prop({ required: true }) path!: string;
  @Prop({ required: true }) node!: any;
  @Prop({ required: true }) matches!: MatchView[];

  pathForParties(party: any) {
    return `${this.path}.${party}`;
  }

  matchesForParties(party: any, prop: string): MatchView[] {
    let matches = [];

    matches = this.matches
      .filter(x => x.field.startsWith(`${this.pathForParties(party)}.${prop}`));

    return matches;
  }
}






import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export default class BookingDetailPartiesTitle extends Vue {
  @Prop({ required: true }) party?: string;
}














import { Component, Prop, Vue } from "vue-property-decorator";

import BookingDetailContainers from "./booking-detail-containers.vue";
import BookingDetailSailings from "./booking-detail-sailings.vue";
import BookingDetailParties from "./booking-detail-parties.vue";
import { HitView, MatchView } from "@/models/case-maintenance";

import { JSONPath } from "jsonpath-plus";

@Component
export default class BookingDetail extends Vue {
  @Prop({ required: true }) booking: any;
  @Prop({ required: true }) path!: string;
  @Prop({ required: true }) hits!: HitView[];

  get node() {
    return JSONPath({ path: this.path, json: this.booking })[0];
  }

  get containerIndex() {
    const indexEtc = this.path.split("[");
    if (indexEtc.length === 1) {
      return 0;
    }
    const indexString = indexEtc[1].split("]");
    const indexValue = parseInt(indexString[0]);
    return isNaN(indexValue) ? indexString[0] : indexValue + 1;
  }

  get pathStart() {
    if (!this.path) return "";

    const splitPathByPoint = this.path.split(".");

    if (splitPathByPoint.length === 1) return splitPathByPoint[0];

    const splitPathByBracket = splitPathByPoint[1].split("[");
    return `$.${splitPathByBracket[0]}`;
  }

  get matches(): MatchView[] {
    return this.hits.flatMap(x => x.matches).filter(x => x.field.startsWith(this.pathStart));
  }

  get component() {
    if (this.path.startsWith("$.sailings")) return BookingDetailSailings;
    if (this.path.startsWith("$.parties")) return BookingDetailParties;
    if (this.path.startsWith("$.cargo-transport-units")) return BookingDetailContainers;
  }
}

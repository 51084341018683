








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MapMarker extends Vue {
  @Prop({ default: "white" }) color!: string;
  @Prop({ default: "rgb(130, 130, 130)" }) iconColor!: string;
  @Prop({ default: "" }) content!: string;
}

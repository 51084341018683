







































import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import BookingDetailCargo from "./booking-detail-cargo.vue";
import { Match } from "@/models/case-maintenance.d";
import { getSingleQueryParam } from "./BookingFunc";

@Component({
  components: {
    cargo: BookingDetailCargo
  }
})
export default class BookingDetailContainer extends Vue {
  @Prop({ required: true }) container: any;
  @Prop({ required: true }) index!: number;
  @Prop({ required: true }) matches!: Match[];
  @Prop({ required: true }) path!: string;

  selectedTab = 0;

  tabClicked(event: any) {
    const path = getSingleQueryParam(this.$route.query.path, "");
    this.$router.replace({ query: { path: path, tabToSelect: event } }).catch(() => true);
  }

  @Watch("$route", { immediate: true })
  tabSelectedChanged() {
    this.$nextTick(() => {
      const tabToSelectStr = getSingleQueryParam(this.$route.query.tabToSelect, "0");
      const tabToSelect = parseInt(tabToSelectStr);
      this.selectedTab = isNaN(tabToSelect) ? this.getCargoIndex(tabToSelectStr) : tabToSelect;
      if (this.$refs.tabs) {
        (this.$refs.tabs as any).callSlider();
      }
    });
  }

  get containerTitle() {
    return this.container ? this.container.$id || (this.index > 0 ? this.index : "") : 1;
  }

  getCargoIndex(index: string): number {
    if (this.container === undefined) {
      return 0;
    } else {
      const result = this.container.cargo.findIndex((x: any) => x.$id === index);
      return result;
    }
  }

  isDangerousGoods(cargo: any) {
    return "un-number" in cargo;
  }

  pathForCargo(cargo: any, index: number): string {
    const indexer = "$id" in cargo ? `?(@.$id == '${cargo.$id}')` : index;

    return `${this.path}.cargo[${indexer}]`;
  }

  matchesForCargo(cargo: any, index: number): Match[] {
    return this.matches.filter(x => x.field.startsWith(this.pathForCargo(cargo, index)));
  }
}

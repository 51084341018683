











import { Component, Prop, Vue } from 'vue-property-decorator';

import { CaseView } from '@/models/case-maintenance';
import { CaseNotes } from '@/models/case-maintenance.d';
import UserControl from '@/components/user.vue';
import CaseHistoryItem from '@/components/case-history/case-history-item.vue';


@Component({
  components: {
    User: UserControl,
    CaseHistoryItem
  }
})
export default class CaseHistoryNote extends Vue {
  @Prop({ required: true }) data!: CaseNotes
  @Prop({ required: true }) model!: CaseView
}

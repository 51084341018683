




















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { CaseView, HitView, MatchView } from '@/models/case-maintenance';
import { highlightSelected } from '@/views/CaseFunc';
import BookingHitNavigation from '../booking/booking-hit-navigation.vue';

@Component({
  components: {
    BookingHitNavigation
  }

})
export default class CaseToolbar extends Vue {
  @Prop() showBackButton?: boolean;
  @Prop() caseModel?: CaseView;
  @Prop() activeCaseTab?: boolean;

  changedHighlightedText(matchView: MatchView) {
    this.$emit('changed-highlightedtext', matchView);
  }

  closeTab() {
    this.$emit('close-tab');
  }

  deselectHit() {
    this.$emit('deselect-hit');
  }

  goToCaseList() {
    this.$emit('goto-caselist');
  }

  
  gotoMatchingCasesSearchTerm = "";
  lookingForMatchingCases = false;

  async gotoMatchingCases() {
    this.lookingForMatchingCases = true;
    this.$emit('goto-matchingcases', this.gotoMatchingCasesSearchTerm);
  }

  goToMatchingCasesDone() {
    this.lookingForMatchingCases = false;
  }

  get highlightSelected() {
    return highlightSelected(this.caseModel);
  }

  get currentHits(): HitView[] {
    return this.caseModel ? this.caseModel.hits.filter(x => x.isCurrent) : [];
  }
}

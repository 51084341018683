/* eslint-disable @typescript-eslint/no-use-before-define */
import { Vue } from "vue-property-decorator";

const knownProperties = ["sailings", "cargo-transport-units", "parties"];

export type BookingNode = {
  id: string;
  name: string;
  children?: BookingNode[];
};

export function getBasePath(path: string | null): string {
  if (path == null) return "";

  if(path.startsWith('$.sailings')) return '$.sailings';
  if(path.startsWith('$.parties')) return '$.parties';

  const split = path.split("]");
  return split[0] + "]";
}

export function getSingleQueryParam(value: string|(string|null)[], defaultValue: string): string {
  if(Array.isArray(value)){
    if (value.length == 0) {
      return defaultValue;
    } else if (value[0]) {
      return value[0];
    } else {
      return defaultValue;
    }
  } else {
    return value;
  }
}

function getId(indexString: string): string {
  const quotedIndexEtc = indexString.split("'");
  const id = quotedIndexEtc[1];
  return id;
}

export function getSubPath(path: string | null): string {
  if (!path) return "";
  const splitPath = path.split("]");

  if(splitPath.length === 1) return path;

  if (splitPath[1] !== "") {

    if(!splitPath[1].includes("[")) return splitPath[1];
    
    const endsInIndex = splitPath[1].split("[");
    const indexJSON = endsInIndex[1].split("[");

    if (indexJSON[0].indexOf("'") == -1) {
      return indexJSON[0];
    } else {
      return getId(indexJSON[0]);
    }
  } else {
    return "[0]";
  }
}

function buildArrayIndex(index: number, value: any[]) {

  if(typeof(value[index])!= 'object')
    return index;

  if ("$id" in value[index]) {
    const id = value[index].$id;

    const duplicateIdCount = value.filter(x => x.$id == id).length

    if(duplicateIdCount !== 1) return index;

    return `?(@.$id == '${id}')`;
  }

  return index;
}

function buildChildren(value: any, parentId: string, depth?: number) {
  if (depth === 0) return [];

  if (Array.isArray(value)) {
    const props: any[] = [];
    for (let i = 0; i < value.length; i++) {
      const indexer = buildArrayIndex(i, value);
      let name = `${i + 1}`;
      if (value[i].$id) {
        name = `${name}: ${value[i].$id}`;
      }

      props.push(buildNode(value[i], name, `${parentId}[${indexer}]`, depth));
    }
    return props;
  }

  if (typeof value === "object") {
    const props: any[] = [];
    for (const prop of Object.keys(value)) {
      props.push(buildNode(value[prop], prop, `${parentId}.${prop}`, depth));
    }
    return props;
  }

  return [];
}

function buildNode(value: any, name: string, id: string, depth?: number) {
  if (typeof value === "object") {
    return {
      id: id,
      name: Vue.filter("kebabToPascal")(name),
      children: buildChildren(value, id, depth && depth - 1)
    };
  }

  return {
    id: id,
    name: `${Vue.filter("kebabToPascal")(name)} = ${value}`
  };
}

export function getBookingNodes(booking: any): BookingNode[] {
  let bookingNodes: BookingNode[] = [];

  if (!booking || Object.keys(booking).length == 0) {
    return [
      {
        id: "1",
        name: "No Booking"
      }
    ];
  }

  const extraRootNodes: string[] = Object.keys(booking)
    .filter(key => knownProperties.indexOf(key) == -1)
    .filter(key => Array.isArray(booking[key]));

  bookingNodes = extraRootNodes
    .map(key => buildNode(booking[key], key, `$.${key}`))
    .concat([
      buildNode(
        booking["cargo-transport-units"],
        "Containers",
        "$.cargo-transport-units",
        2
      ),
      buildNode(booking["sailings"], "Sailings", "$.sailings", 1),
      buildNode(booking["parties"], "Parties", "$.parties", 1)
    ]);

  return bookingNodes;
}

export function calculatePath(fieldPath: string): string {
  if (fieldPath.includes("cargo-transport-units") || fieldPath.includes("sailings"))
    return fieldPath;
  
  return fieldPath.split('.').filter((_, index) =>  index < 2).join(".");
}

export function extractId(index: string): string {
  return index.substring(index.indexOf('\'') + 1, index.lastIndexOf('\''));
}
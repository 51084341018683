














import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HighlightTextToken, Match } from "@/models/case-maintenance.d";

@Component
export default class HighlightMatchesComponent extends Vue {
  @Prop({ required: true }) value!: string;
  @Prop({ required: false }) matches!: Match[];
  @Prop({ required: false }) suppliedTokens!: HighlightTextToken[];

  tokens: { text: string; isHighlighted: boolean; isHighlightedSingle: boolean }[] = [];

  @Watch("value", { immediate: true })
  onValueChange() {
    this.generateTokens();
  }

  @Watch("matches", { immediate: true })
  onMatchChange() {
    this.generateTokens();
  }

  generateTokens() {
    if (this.suppliedTokens) {
      this.tokens = this.suppliedTokens;
    }

    if (!this.matches) {
      return [
        { text: this.value, isHighlighted: false, isHighlightedSingle: false } as HighlightTextToken
      ];
    }
    this.tokens = [];

    if (this.matches.length === 0) {
      this.tokens.push({
        text: this.value,
        isHighlighted: false,
        isHighlightedSingle: false
      } as HighlightTextToken);

      return;
    }

    const matches = [...this.matches].sort((a, b) => (a.position > b.position ? 1 : -1));
    const spanMarkers = new Array(this.value.length).fill(0);

    for (const match of matches) {
      for (let i = match.position - 1; i < match.position + match.length - 1; i++) {
        if (spanMarkers[i] == 2) continue;
        spanMarkers[i] = match.singleHighlight ? 2 : 1;
      }
    }

    const highlightIsActive = spanMarkers.filter(x => x === 2).length > 0;

    let lastTokenVal = spanMarkers[0];
    let lastIndex = 0;

    for (let i = 0; i < spanMarkers.length; i++) {
      if (spanMarkers[i] != lastTokenVal) {
        this.tokens.push({
          text: this.value.substring(lastIndex, i + 1),
          isHighlighted: lastTokenVal == 1 && !highlightIsActive ? true : false,
          isHighlightedSingle: lastTokenVal == 2 ? true : false
        });
        lastTokenVal = spanMarkers[i];
        lastIndex = i + 1;
      }
    }

    this.tokens.push({
      text: this.value.substring(lastIndex, spanMarkers.length),
      isHighlighted: spanMarkers[spanMarkers.length - 1] == 1 ? true : false,
      isHighlightedSingle: spanMarkers[spanMarkers.length - 1] == 2 ? true : false
    });
  }
}























import { Component, Prop, PropSync, Vue, Inject } from 'vue-property-decorator'
import Booking from '../booking/booking.vue';
import ScreeningViewService from '@/services/screening-view-service';
import { HitView } from '@/models/case-maintenance';
import { screenResultsToHitView } from '../helper/ScreenResultMapper';
import { ScreenResult } from '@/models/case-maintenance.d';

@Component({
  components: {
      Booking
  }
})
export default class CaseHistoryBooking extends Vue {
 @Inject() ScreeningViewService!: ScreeningViewService;
 @PropSync("dialogOpen", { required: true }) dialogOpenSync!: boolean;
 @Prop({ required: true }) data!:  ScreenResult

 booking: any = {};
 hits: HitView[] = [];
 screeningId= "";

 async mounted(){
  await this.loadBooking();
  this.screeningId = this.data["screening-id"];
}

 async loadBooking(): Promise<void> {
    this.hits = screenResultsToHitView([this.data])
    this.booking = await this.ScreeningViewService.readScreening(this.data["screening-id"])
  }
}
